
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { DeviceInfo } from '@/types/pondMonitor'

@Component
export default class PondMonitorDetailList extends Vue {
  @Prop() private radio!: boolean
  private searchInfo: DeviceInfo = { deviceNumber: '', deviceStatus: '' }
  private tableData: { loading: boolean; data: DeviceInfo[] } = {
    loading: false,
    data: []
  }

  private page = 1
  private size = 10
  private total = 0

  created () {
    this.getData()
  }

  get projectId () {
    return this.$route.params.projectId as string || ''
  }

  getData () {
    this.tableData.loading = true
    this.$axios.get(this.$apis.pondMonitor.selectDraftPage, {
      ...this.searchInfo,
      projectId: this.projectId,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData.data = res.list || []
    }).finally(() => {
      this.tableData.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  // 历史数据
  toHistory (value: string) {
    this.$router.push({
      name: 'pondMonitorListHistory',
      params: { deviceId: value, projectId: this.projectId }
    })
  }
}
